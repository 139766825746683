<div class="bottom-bar">

    <!-- Links sections -->
    <div class="bottom-sections-container">
      
        <!-- For Food lovers -->
        <div class="bottom-item">
            <div class="bottom-item-icon">
              <img [src]="'apps/landing-page/src/assets/bottom-food-burger.svg'">
            </div>
            <div class="bottom-item-text">
              <div class="bottom-item-title">
                {{ "bottom_section_food_title" | translate }}
              </div>
              <div class="bottom-item-title-separator">
              </div>
              <div class="bottom-item-links">
                <div class="bottom-item-link">
                  <a [href]="getLoginUrl()">{{ "bottom_section_food_login" | translate }}</a> <span class="pl-1 pr-1"> / </span> 
                  <a [href]="getSignupUrl()">{{ "bottom_section_food_register" | translate }}</a>
                </div>
                <div class="bottom-item-link"><button (click)="onSearchFoodPress('large')">{{ "bottom_section_food_search" | translate }}</button></div>
                <div class="bottom-item-link"><a [href]="getAndroidUrl()">{{ "bottom_section_food_ios" | translate }}</a></div>
                <div class="bottom-item-link"><a [href]="getiOSUrl()">{{ "bottom_section_food_android" | translate }}</a></div>
                <div class="bottom-item-link"><a href="/expansion-plan">{{ "bottom_section_food_expansion" | translate }}</a></div>
              </div>
              
            </div>
          </div>

        <!-- For Restaurants -->
        <div class="bottom-item">
          <div class="bottom-item-icon">
            <img [src]="'apps/landing-page/src/assets/bottom-restaurant-delivery.svg'">
          </div>
          <div class="bottom-item-text">
            <div class="bottom-item-title">
              {{ "bottom_section_restaurant_title" | translate }}
            </div>
            <div class="bottom-item-title-separator">
            </div>
            <div class="bottom-item-links">
              <div class="bottom-item-link"><a href="/restaurant">{{ "bottom_section_restaurant_plans" | translate }}</a></div>
              <div class="bottom-item-link"><a [href]="getRestaurantgSignupUrl()">{{ "bottom_section_restaurant_register" | translate }}</a></div>
              <div class="bottom-item-link"><a href="">{{ "bottom_section_restaurant_kitchen" | translate }}</a></div>
              <div class="bottom-item-link"><a href="">{{ "bottom_section_restaurant_driver" | translate }}</a></div>
              <div class="bottom-item-link"><a [href]="getLoginUrl()">{{ "bottom_section_restaurant_login" | translate }}</a></div>
            </div>
            
          </div>
        </div>

      <!-- For Legal terms -->
      <div class="bottom-item">
        <div class="bottom-item-icon">
          <img [src]="'apps/landing-page/src/assets/bottom-legal-documents.svg'">
        </div>
        <div class="bottom-item-text">
          <div class="bottom-item-title">
            {{ "bottom_section_legal_title" | translate }}
          </div>
          <div class="bottom-item-title-separator">
          </div>
          <div class="bottom-item-links">
            <div class="bottom-item-link"><a href="">{{ "bottom_section_legal_terms" | translate }}</a></div>
            <div class="bottom-item-link"><a href="">{{ "bottom_section_legal_privacy" | translate }}</a></div>
            <div class="bottom-item-link"><a href="">{{ "bottom_section_legal_data" | translate }}</a></div>
            <div class="bottom-item-link"><a href="">{{ "bottom_section_legal_cookies" | translate }}</a></div>
          </div>
        </div>
      </div>

      <!-- For Support-->
      <div class="bottom-item">
        <div class="bottom-item-icon">
          <img [src]="'apps/landing-page/src/assets/bottom-ask-support.svg'">
        </div>
        <div class="bottom-item-text">
          <div class="bottom-item-title">
            {{ "bottom_section_support_title" | translate }}
          </div>
          <div class="bottom-item-title-separator">
          </div>
          <div class="bottom-item-links">
            <div class="bottom-item-link"><a href="">{{ "bottom_section_support_faq" | translate }}</a></div>
            <div class="bottom-item-link"><a href="">{{ "bottom_section_support_user" | translate }}</a></div>
            <div class="bottom-item-link"><a href="">{{ "bottom_section_support_restaurant" | translate }}</a></div>
            <div class="bottom-item-link"><a href="">{{ "bottom_section_support_contact" | translate }}</a></div>
          </div>
        </div>
      </div>


    <!-- End links section: -->
    </div>

    <div class="bottom-trademark-container">
      <div class="bottom-trademark-logo">
        <fs-logo [whiteVersion]="true" class="bottom-trademark-logo-comp"></fs-logo>
      </div>
      <div class="bottom-trademark-text">
        {{ "trademark_text" | translate }}{{ currentYear }}
      </div>
    </div>

</div>