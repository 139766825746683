import { Injectable, OnInit } from "@angular/core";
import { BaseLoadingService } from "libs/shared-services/src/lib/base-loading";
import { CookieService } from "libs/shared-services/src/lib/cookie.service";
import { BehaviorSubject, Observable } from "rxjs";

@Injectable({
    providedIn: 'root',
})
export class LandingHiddenService extends BaseLoadingService implements OnInit {

    private isHiddenVersion$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    constructor(
        private cookieService: CookieService
    ) {
        super();
    }

    public ngOnInit() {
        
    }

    public initService() {
        const cookie = this.cookieService.getCookie("letmein");
        this.setHiddenVersion(cookie === "true");
        this.setFinished();
    }

    public setHiddenVersion(b: boolean) {
        if (b === this.isHiddenVersion$.getValue()) {
            return;
        }
        this.cookieService.setCookie('letmein', JSON.stringify(b));
        this.isHiddenVersion$.next(b);
    }

    public getHiddenVersion$(): Observable<boolean> {
        return this.isHiddenVersion$.asObservable();
    }
}
