import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from 'apps/landing-page/src/environments/environment';
import { AddressResponse } from 'libs/shared-models/src/lib/address-response';
import { mapViennaOptions, mapViennaOptionsCenter } from 'libs/shared-models/src/lib/utils/address-constants';
import { CookieService } from 'libs/shared-services/src/lib/cookie.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { v4 as uuidv4 } from "uuid";
import * as CryptoJS from 'crypto-js';
import { ToasterService } from 'libs/shared-services/src/lib/toaster.service';

@Component({
  selector: 'address-search',
  templateUrl: './address-search.component.html',
  styleUrls: ['./address-search.component.scss'],
})
export class AddressSearchComponent implements AfterViewInit {

  // Clear X
  private clearButtonVisibility$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false); // Small functionality
  // Green button
  public enableSearchButton$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);


  // Input field itself
  @ViewChild('addressSearchField') searchField: ElementRef | undefined;

  // Scroll
  @Input() set scrollToInput(value: "small" | "medium" | "large" | "none") {
    if (!value || value === "none") {
      return;
    }
    this.onSearchFoodPress(value);
  }

  // Google maps logic
  private gAutocomplete: google.maps.places.Autocomplete | undefined // Places Autocomplete container
  public defaultMapOptions: google.maps.MapOptions = mapViennaOptions; // Boundaries region (Vienna)

  private temporaryAddress$: BehaviorSubject<AddressResponse> = new BehaviorSubject<AddressResponse>(new AddressResponse(true)); // Edit one

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private cdRef: ChangeDetectorRef,
    private cookieService: CookieService,
    private toasterService: ToasterService 
  ) {    
  }

  // Needed in the viewAfterInit because of the searchInput which needs to be loaded
  public ngAfterViewInit(): void {
    // uses the property added in index.html which waits for the async google map script to load
    if ((window as any).googleMapsInitialized) {
      this.setupInput(); 
      this.cdRef.detectChanges();
    } else {
      // Wait for the googleMapsLoaded event before initializing the map
      document.addEventListener('googleMapsLoaded', () => {
        this.setupInput(); 
        this.cdRef.detectChanges();
      });
    }
  }

  /*
       Clear button
  */
  public isClearButtonVisible$(): Observable<boolean> {
    return this.clearButtonVisibility$.asObservable();
  }

  public onClearClick() {
    if (this.searchField) {
        this.searchField.nativeElement.value = "";
        // clear the temp address too:
        this.setTempAddress(new AddressResponse(true));
        this.enableSearchButton$.next(false);
    }
    this.clearButtonVisibility$.next(false);
  }

  public inputValueChanged() {
    if (!this.searchField?.nativeElement.value) {
      this.clearButtonVisibility$.next(false);
    } else {
      this.clearButtonVisibility$.next(true);
    }
  }


  /*
      Scroll & Focus to input
  */
  public onSearchFoodPress(scrollDistance: "small" | "medium" | "large" = "small") {

    // If it has address stored, go directly to the app
    const address = this.getTempAddress();
    if (address && address.latitude && address.longitude) {
      // store temp address
      this.setAddressOnCookie();

      // start to app product
      window.location.href = environment.PRODUCT_URLS.USER_APP;
      return;
    }


    let timeoutDuration = 500; // some default if scrollDistance is not a match
    if (scrollDistance === "small") {
      timeoutDuration = 200;
    } else if (scrollDistance === "medium") {
      timeoutDuration = 550;
    } else if (scrollDistance === "large") {
      timeoutDuration = 700;
    }

    this.searchField?.nativeElement.scrollIntoView({ behavior: 'smooth', block: "end", inline: "nearest"});
    setTimeout(() => {
      this.searchField?.nativeElement.focus();
    }, timeoutDuration); // duration of the "scrollIntoView" smooth animation   
  }

  private setAddressOnCookie() {

    // Check if an ID exists in local storage
    let uniqueID = this.cookieService.getCookie('fs-temp-landing-address-key');
    if (!uniqueID) {
        // Generate a new UUID 4 unique ID and store it
        uniqueID = uuidv4();
        this.cookieService.setCookie('fs-temp-landing-address-key', uniqueID);            
    }

    const address = this.getTempAddress();
    const encryptedAddress = CryptoJS.AES.encrypt(JSON.stringify([address]), uniqueID).toString();
    this.cookieService.setCookie('fs-temp-landing-address-value', encryptedAddress);
  }


  /*
    Google maps related logic
  */

  private setupInput() {

    // Search, Places API
    if (this.searchField) {

        // Focus the input cursor / select input
        this.searchField.nativeElement.focus();

        // Create a bounding box with sides ~17km away from the center point
        const center = mapViennaOptionsCenter;
        const defaultBounds = {
            north: center.lat + 0.17,
            south: center.lat - 0.17,
            east: center.lng + 0.17,
            west: center.lng - 0.17,
        };
        const options = {
            bounds: defaultBounds,
            componentRestrictions: { country: "at" },
            fields: ["address_components", "geometry", "icon", "name"],
            strictBounds: true,
        };

        this.clearMapsListener();

        //console.log("===> this.searcField", this.searchField);
        this.gAutocomplete = new google.maps.places.Autocomplete(this.searchField.nativeElement, options);
        this.gAutocomplete.addListener('place_changed', () => {
            this.onPlaceSelected();
        })
    } else {
        // An error appeared when loading Google api
    }
  }


  // clear any existing listener / instance
  private clearMapsListener() {
    if (this.gAutocomplete) {
        this.gAutocomplete.unbindAll();
        google.maps.event.clearListeners(this.gAutocomplete, 'place_changed');
    }
  }

  /*
    Listen to Google Search dropdown address Select
  */
  private onPlaceSelected() {
      const place = this.gAutocomplete?.getPlace();
      this.parseAddressResponse(place);
  }

  // Used by both Places Autocomplete (input) and the Geocoding (browser target location)
  private parseAddressResponse(place: any) {
    if (!place) {
        return;
    }

    if (!place.address_components || place.address_components?.length === 0) {
        return;
    }

    // Our internal model
    let address;
    // Check if we just need to edit (and clear) an existing address (which gets new geolocation, or if it's the first time and we generate a new one)
    if (!this.getTempAddress().getIsTemporary() || !this.getTempAddress().hasGeneratedId() || this.getTempAddress().getIsLocallyStored()) {
        address = Object.assign(new AddressResponse(), this.getTempAddress())
        address.setStreetName("");
        address.setStreetNumber("");
        address.setPostalCode("");
    } else {
        address = new AddressResponse(true);
    }

    // Address components (street, zip, city):
    for (const addressComponent of place.address_components) {
        const type = addressComponent.types[0];

        switch (type) {
            case "locality": {
                address.setCity(addressComponent.long_name);
                break;
            }
            case "route": {
                address.setStreetName(addressComponent.short_name + address.getStreetName())
                break;
            }
            case "street_number": {
                address.setStreetNumber(addressComponent.long_name)
                break;
            }
            case "country": {
                address.setCountry(addressComponent.long_name)
                break;
            }
            case "administrative_area_level_1": {
                address.setState(addressComponent.short_name)
                break;
            }
            case "postal_code": {
                address.setPostalCode(addressComponent.long_name + address.getPostalCode());
                break;
            }
            case "postal_code_suffix": {
                address.setPostalCode(address.getPostalCode() + "-" + addressComponent.long_name);
                break;
            }
            case "sublocality_level_1": {
                address.setDistrict(addressComponent.long_name);
                break;
            }
            default:
                break;
        }
    }

    // Geometry components (latitude, longitude)
    if (!!place.geometry && !!place.geometry.location) {
        const lat = place.geometry.location.lat();
        const long = place.geometry.location.lng();
        address.setLatitude(lat ? lat : 0);
        address.setLongitude(long ? long : 0);
    }

    // Update current state
    this.setTempAddress(address);
    this.cdRef.detectChanges();

    // Enable the search food button:
    this.enableSearchButton$.next(true);
  }

  public getTempAddress$(): Observable<AddressResponse> {
    return this.temporaryAddress$.asObservable();
  }

  public getTempAddress(): AddressResponse {
      return this.temporaryAddress$.getValue();
  }

  private setTempAddress(a: AddressResponse): void {
      const newAddress = Object.assign(new AddressResponse(), a);
      this.temporaryAddress$.next(newAddress); 
  }


  /*
        Browser target location
  */
  public onBrowserLocationClick() {
  
    // Try HTML5 geolocation.
    if (navigator.geolocation) {      
        navigator.geolocation.getCurrentPosition(
            (position: GeolocationPosition) => {
                const pos = {
                    lat: position.coords.latitude,
                    lng: position.coords.longitude,
                };
                console.log("==> pos", pos);
                this.handleGeocoding(pos);
            },
            (error: GeolocationPositionError) => {
              console.error("Error occurred. Error code: " + error.code);
              let message = "";

              switch(error.code) {
                  case error.PERMISSION_DENIED:
                      message = "Please allow the browser to have acess to geolocation";                      
                      this.toasterService.showWarning("", message);                      
                      break;
                  case error.POSITION_UNAVAILABLE:
                      message = "Unfortunately, the location information is not available in the browser."
                      this.toasterService.showWarning("", message);                     
                      break;
                  case error.TIMEOUT:
                      message = "The request to get user location timed out."
                      this.toasterService.showWarning("", message);        
                      break;
                  default:
                      console.error("An unknown error occurred.");
                      message = "An unknown error occurred while getting location."
                      this.toasterService.showWarning("", message); 
                      break;
              }
              // Additional handling or notifying user
          }
        );
    } else {
        // Browser doesn't support Geolocation
        const message = "The browser doesn't support location functionality";                      
        this.toasterService.showWarning("", message);                
    }
  }
  
  /*
      Access the Geocoding API to transform latitude/longitude in Address
  */
  private handleGeocoding(position: any) {
      const geocoder = new google.maps.Geocoder();
      let place = null;

      geocoder.geocode({ location: position })
          .then((response) => {
              if (response.results[0]) {
                  place = response.results[0];

                  if (place) {
                      this.parseAddressResponse(place); // Set the address
                      const newAddress = this.getTempAddress();
                      if (this.searchField) {
                        this.searchField.nativeElement.value = 
                          newAddress.streetName + " " + 
                          newAddress.streetNumber + ", " + 
                          newAddress.postalCode + 
                          (newAddress.district ? "" + newAddress.district : "") + ", " + 
                          newAddress.city
                      }                      
                  }
              } else {
                  console.warn("Browser location: Google Geocoding API - No results found");
              }
          })
          .catch((e) => console.error("Geocoder failed due to: " + e));
  }
}
