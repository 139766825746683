import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from 'apps/landing-page/src/environments/environment';

@Component({
  selector: 'landing-footer',
  templateUrl: './landing-footer.component.html',
  styleUrls: ['./landing-footer.component.scss'],
})
export class LandingFooterComponent implements OnInit {

  public currentYear = new Date().getFullYear();

  constructor(
    private router: Router,
    private route: ActivatedRoute,
  ) {
    
  }

  public ngOnInit() {
  }

  // links (maybe move this in separate component)
  public getLoginUrl(): string {
    return environment.PRODUCT_URLS.ACCOUNT;
  }

  public getSignupUrl(): string {
    return environment.PRODUCT_URLS.ACCOUNT_USER_REGISTRATION;
  }

  public getRestaurantgSignupUrl(): string {
    return environment.PRODUCT_URLS.ACCOUNT_BUSINESS_REGISTRATION;
  }

  public getAndroidUrl(): string {
    return environment.MOBILE_GOOGLE_PLAY_URL;
  }

  public getiOSUrl(): string {
    return environment.MOBILE_APP_STORE_URL;
  }

  public onSearchFoodPress(s: string) {
    // TODO: make this work to scroll to top or to go on homepage and search for food (in case the link is clicked in a page outside dashboard)
  }
}
