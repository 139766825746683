export const environment = {
    BUILD_PRODUCT: "landing-page",
    API_URL_BASE: "https://api.foodis.day/",
    API_USER_SELF: "v1/users/self",
    PRODUCT_URLS: {
        LANDING_PAGE: "https://staging.foodis.day",
        ACCOUNT: "https://account.staging.foodis.day",
        ACCOUNT_USER_REGISTRATION: "https://account.staging.foodis.day/register",
        ACCOUNT_BUSINESS_REGISTRATION: "https://account.staging.foodis.day/register-business",
        RESTAURANT: "https://restaurant.staging.foodis.day",
        USER_APP: "https://app.staging.foodis.day",
    },
    MOBILE_GOOGLE_PLAY_URL: "https://play.google.com/",
    MOBILE_APP_STORE_URL: "https://www.apple.com/app-store/",
    DEBUG_STAGING_BADGE: true
};
