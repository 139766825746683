import { AfterViewInit, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { AuthService } from "libs/shared-services/src/lib/auth.service";
import { UntilDestroy } from "@ngneat/until-destroy";
import { Router } from '@angular/router';
import { Product } from 'libs/shared-models/src/lib/product-type';
import { Observable } from 'rxjs';
import { LandingHiddenService } from '../../services/landing-hidden.service';
import { environment } from 'apps/landing-page/src/environments/environment';
import { LandingTopNavBarService } from '../../services/landing-top-navbar.service';
import { UserSelfResponse } from '../../models/user-self-response';

@UntilDestroy()
@Component({
  selector: 'landing-top-nav-bar',
  templateUrl: './landing-top-nav-bar.component.html',
  styleUrls: ['./landing-top-nav-bar.component.scss'],
})
export class LandingTopNavBarComponent {

    public Product: typeof Product = Product;

    public landingHiddenVisible$: Observable<boolean> = this.landingHiddenService.getHiddenVersion$();
    public selfData$: Observable<UserSelfResponse> = this.landingNavBarService.getSelfData$();

    constructor(
        private authService: AuthService,
        private ref: ChangeDetectorRef,
        private router: Router,
        private landingHiddenService: LandingHiddenService,
        private landingNavBarService: LandingTopNavBarService
    ) {
    }

    public onLogoPress() {
        this.router.navigateByUrl("/");
    }

    public onLoginPress() {
      window.location.href = window.location.href = environment.PRODUCT_URLS.ACCOUNT;
    }

    public onRegisterPress() {
      window.location.href = window.location.href = environment.PRODUCT_URLS.ACCOUNT_USER_REGISTRATION;
    }

    public onRestaurantPress() {

    }

    public isLoggedIn$(): Observable<boolean> {
      return this.authService.$getLoginState();
    }

    public doLogout() {
      this.authService.doLogout();
      // TODO in the future: Additionally delete any locally stored cookie/localstorage data
    }

    public getUserAppBaseLink(): string {
      return  environment.PRODUCT_URLS.USER_APP;
    }
}