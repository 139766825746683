<!-- Input address area -->
<div class="input-field-container">

    <!-- Search icon -->                
    <div class="address-search-icon">
        <mat-icon class="addres-search-icon-mat">location_on</mat-icon>
    </div>
    
    <!-- Input field -->
    <input #addressSearchField class="search-input" (input)="inputValueChanged()" type="text" placeholder="{{ 'mid_search_input' | translate }}">

    <!-- Clear text (x) icon -->
    <ng-container *ngIf="isClearButtonVisible$() | async">
      <div class="divider-clear">
        <div class="address-clear-text-icon" (click)="onClearClick()">
          {{ "mid_search_clear_button" | translate}}
          <mat-icon>close</mat-icon>
        </div>
      </div>
    </ng-container>

    <div class="white-right-background" [ngClass]="{'isClearVisible': isClearButtonVisible$() | async}">
    </div>

    <!-- Browser location - geo-targeting -->
    <div class="address-current-target" (click)="onBrowserLocationClick()">
      <img [src]="'./libs/shared-ui/assets/images/nav-target-location.svg'" class="address-current-target-img" alt="Use browser address location"/>
    </div>
</div>


<div class="search-food-btn-container">
    <div class="search-food-button" [ngClass]="{'shine-active': (enableSearchButton$ | async)}">
      <!-- Search food button -->
      <fs-button [text]="'mid_search_food_button' | translate"
        [type]="(enableSearchButton$ | async) ? 'raised-primary' : 'raised'" [leadingIcon]="'search'"
        [style]="'full-width-large-height'"
        (mouseup)="onSearchFoodPress('small')"
        class="logout-btn">
      </fs-button>
    </div>
  </div>
