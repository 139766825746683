import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, combineLatest } from 'rxjs';
import { LandingHiddenService } from './landing-hidden.service';
import { LandingTopNavBarService } from './landing-top-navbar.service';


@Injectable({
  providedIn: 'root'
})
export class LandingMainServicesInitService {

    private allLoaded$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    constructor(
      private landingHiddenService: LandingHiddenService,
      private landingTopNavBarService: LandingTopNavBarService
    ) {
    }

    public initServices() {

        this.landingHiddenService.initService();
        this.landingTopNavBarService.initNavBar();

        combineLatest([
            this.landingHiddenService.isFinished$(),
            this.landingTopNavBarService.isFinished$(),
        ]).subscribe(
            ([hiddenService, topNavBarService]) => {

                // if already loaded before and some service emits again, ignore it
                if (this.allLoaded$.getValue()) {
                    return; 
                }

                // make sure all are loaded
                if (hiddenService && topNavBarService) {
                    this.allLoaded$.next(true);
                }            
            }
        );
    }

    public allServicesLoaded$(): Observable<boolean> {
        return this.allLoaded$.asObservable();
    }
}
