<!-- 
  Actual content
-->

<ng-container *ngIf="landingHiddenVisible$ | async; else showCountdown">
  
  <div class="main-container">


    <!-- Hill & Bike animation -->
    <div class="hillContainer">
      <div id="curveContainer">
        <!-- The background SVG with the filled area -->
        <svg id="backgroundSvg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" preserveAspectRatio="none">
          <!-- Define the gradient -->
          <defs>
            <linearGradient id="greenGradient" x1="0%" y1="0%" x2="0%" y2="100%">
              <stop offset="30%" style="stop-color:#6DB718; stop-opacity:1" />
              <stop offset="100%" style="stop-color:#5E9925; stop-opacity:1" />
            </linearGradient>
          </defs>
            <!-- Curve with filled surface -->
            <path d="M -5 60 Q 23 76 56 64 T 105 60 L 105 100 L -5 100 Z"  fill="url(#greenGradient)"/>
        </svg>
    
        <!-- The initial curve SVG -->
        <svg id="curveSvg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" preserveAspectRatio="none">
            <path id="curve" d="M -5 60 Q 23 76 56 64 T 105 60" stroke="#6DB718" stroke-width="2" fill="transparent"/>
        </svg>
    
        <!-- The bicycle SVG -->
        <svg id="bikeSvg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 36 36">
            <path d="M15.0016 21.9C14.8016 19.9 13.8016 18.1 12.1016 17L9.60156 21.9H15.0016Z" fill="#5E9925"/>
            <path d="M7.2 23.4004C7 23.1004 7 22.7004 7.2 22.4004L10.4 16.1004C9.8 15.9004 9.2 15.9004 8.6 15.9004C5 15.9004 2 18.8004 2 22.4004C2 26.0004 4.9 28.9004 8.5 28.9004C11.5 28.9004 14.1 26.8004 14.8 23.9004H8C7.7 23.9004 7.3 23.7004 7.2 23.4004Z" fill="#5E9925"/>
            <path d="M18.9969 21.9004H14.9969V22.4004C14.9969 22.9004 14.8969 23.4004 14.7969 23.9004H18.9969V21.9004Z" fill="#5E9925"/>
            <path d="M27.5009 15.9004C27.2009 15.9004 26.9009 15.9004 26.6009 16.0004L29.0009 22.6004C29.2009 23.1004 28.9009 23.7004 28.4009 23.9004C28.3009 23.9004 28.2009 24.0004 28.1009 24.0004C27.7009 24.0004 27.3009 23.7004 27.2009 23.3004L24.8009 16.6004C21.6009 18.2004 20.3009 22.1004 21.8009 25.3004C23.4009 28.5004 27.3009 29.8004 30.5009 28.3004C33.7009 26.7004 35.0009 22.8004 33.5009 19.6004C32.2009 17.3004 30.0009 15.9004 27.5009 15.9004Z" fill="#5E9925"/>
            <path d="M24.7 16.7004C25.3 16.4004 26 16.2004 26.6 16.1004L23.9 8.70039C23.8 8.20039 23.4 8.00039 23 7.90039H20C19.4 7.90039 19 8.40039 19 9.00039C19 9.50039 19.4 9.90039 20 9.90039H22.3L23 11.9004H13.4L11.7 10.2004C11.5 10.1004 11.3 10.0004 11 10.0004H8C7.4 10.0004 7 10.4004 7 11.0004C7 11.6004 7.4 12.0004 8 12.0004H10.6L11.8 13.2004L10.3 16.2004C10.9 16.4004 11.6 16.7004 12.1 17.0004L13.7 13.8004H23.7L24.7 16.7004Z" fill="#5E9925"/>
        </svg>

        <!-- The bicycle SVG loaded -->
        <svg id="bikeLoadedSvg" width="44" height="42" viewBox="0 0 44 42" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M19.3514 25.1971C19.1713 22.9234 18.0789 20.858 16.1761 19.5702L13.2252 25.0707L19.3514 25.1971Z" fill="#5E9925"/>
          <path d="M10.466 26.7163C10.2461 26.3713 10.2555 25.9175 10.4894 25.5818L14.2672 18.5095C13.5912 18.2685 12.9105 18.2545 12.2298 18.2404C8.14565 18.1562 4.67431 21.376 4.59005 25.4601C4.50578 29.5443 7.72792 32.9022 11.8121 32.9864C15.2155 33.0566 18.2144 30.7351 19.0764 27.4614L11.3619 27.3023C11.0215 27.2953 10.5724 27.059 10.466 26.7163Z" fill="#5E9925"/>
          <path d="M23.8859 25.2897L19.348 25.1961L19.3363 25.7633C19.3246 26.3306 19.1994 26.8955 19.0743 27.4604L23.8391 27.5587L23.8859 25.2897Z" fill="#5E9925"/>
          <path d="M33.6713 18.6792C33.331 18.6722 32.9906 18.6652 32.6479 18.7716L35.2162 26.3154C35.4314 26.8873 35.077 27.561 34.5051 27.7762C34.3917 27.7738 34.2759 27.8849 34.1624 27.8826C33.7086 27.8732 33.2619 27.5235 33.1578 27.0674L30.5918 19.4102C26.924 21.1504 25.3579 25.5445 26.9847 29.21C28.725 32.8778 33.1191 34.4439 36.7845 32.8171C40.4524 31.0768 42.0185 26.6827 40.3916 23.0173C38.9706 20.3775 36.5075 18.7377 33.6713 18.6792Z" fill="#5E9925"/>
          <path d="M30.476 19.5267C31.1637 19.2004 31.9626 18.9899 32.6456 18.8905L29.7557 10.4321C29.6539 9.86248 29.2048 9.62622 28.7534 9.50341L25.3499 9.43319C24.6692 9.41915 24.2037 9.97703 24.1897 10.6577C24.178 11.225 24.6224 11.6881 25.3031 11.7022L27.9124 11.756L28.6597 14.0414L17.7687 13.8167L15.8798 11.8482C15.6553 11.7301 15.4307 11.612 15.0904 11.605L11.6869 11.5347C11.0062 11.5207 10.543 11.9651 10.529 12.6458C10.515 13.3265 10.9594 13.7897 11.6401 13.8037L14.5898 13.8646L15.923 15.254L14.1511 18.6224C14.8271 18.8633 15.6142 19.2201 16.1745 19.5721L18.0645 15.9792L29.4094 16.2133L30.476 19.5267Z" fill="#5E9925"/>
          <rect y="1" width="13" height="13" fill="#5E9925"/>
          <path d="M9.62171 8.0015C9.26887 7.18919 10.2108 7.00724 10.728 7.01784C10.6225 7.13152 10.5861 7.34094 10.5598 7.43523C10.3737 8.10431 9.91957 8.11025 9.72556 8.04281C9.7332 7.90431 9.92457 7.45852 10.2052 7.28575C9.83754 7.45144 9.67178 7.98304 9.63486 8.22814L9.55604 8.22694L9.62171 8.0015Z" fill="white"/>
          <path d="M2.28802 8.32457C2.45839 8.32457 2.55839 8.37148 2.58802 8.46531C2.60284 8.51222 2.61024 8.57395 2.61024 8.65049C2.61024 8.72704 2.6016 8.78876 2.58432 8.83568C2.5695 8.88012 2.54481 8.91222 2.51024 8.93197C2.45839 8.9616 2.38308 8.97642 2.28432 8.97642L2.04728 8.9616V9.9542C2.04728 10.048 2.03493 10.1184 2.01024 10.1653C1.98802 10.2098 1.95222 10.2394 1.90284 10.2542C1.85592 10.269 1.79419 10.2764 1.71765 10.2764C1.64111 10.2764 1.57938 10.269 1.53247 10.2542C1.48802 10.2369 1.45592 10.211 1.43617 10.1764C1.41642 10.1394 1.40407 10.106 1.39913 10.0764C1.39666 10.0468 1.39543 10.0036 1.39543 9.94679V8.96531H1.26209C1.1979 8.96037 1.15222 8.94555 1.12506 8.92086C1.07814 8.87395 1.05469 8.78383 1.05469 8.65049C1.05469 8.44802 1.12753 8.34679 1.27321 8.34679H1.39543V8.15049C1.39543 7.96037 1.46333 7.78876 1.59913 7.63568C1.73493 7.48012 1.93123 7.40234 2.18802 7.40234C2.24481 7.40234 2.28679 7.40481 2.31395 7.40975C2.34358 7.41222 2.37691 7.42333 2.41395 7.44308C2.48061 7.47518 2.51395 7.57024 2.51395 7.72827C2.51395 7.84679 2.49296 7.93197 2.45098 7.98383C2.41642 8.02333 2.3621 8.04555 2.28802 8.05049C2.2658 8.05049 2.23123 8.05049 2.18432 8.05049C2.09296 8.05049 2.04728 8.08136 2.04728 8.14308V8.34679L2.28802 8.32457Z" fill="white"/>
          <path d="M2.68678 9.29864C2.68678 9.00234 2.79048 8.7579 2.99789 8.56531C3.2053 8.37271 3.44233 8.27642 3.709 8.27642C3.97567 8.27642 4.21147 8.37271 4.41641 8.56531C4.62134 8.75543 4.72381 8.99864 4.72381 9.29494C4.72381 9.49987 4.67073 9.68259 4.56455 9.84308C4.45838 10.0011 4.32875 10.1184 4.17567 10.1949C4.02505 10.269 3.86826 10.306 3.7053 10.306C3.54233 10.306 3.38431 10.2665 3.23122 10.1875C3.07813 10.106 2.94851 9.98753 2.84233 9.83197C2.73863 9.67395 2.68678 9.49617 2.68678 9.29864ZM3.46085 9.56531C3.53986 9.62457 3.62011 9.6542 3.70159 9.6542C3.78307 9.6542 3.86455 9.62333 3.94604 9.5616C4.02752 9.49987 4.06826 9.40852 4.06826 9.28753C4.06826 9.16654 4.02999 9.07642 3.95344 9.01716C3.8769 8.9579 3.79418 8.92827 3.7053 8.92827C3.61641 8.92827 3.53369 8.95913 3.45715 9.02086C3.3806 9.08259 3.34233 9.17395 3.34233 9.29494C3.34233 9.41345 3.38184 9.50358 3.46085 9.56531Z" fill="white"/>
          <path d="M4.90755 9.29864C4.90755 9.00234 5.01126 8.7579 5.21866 8.56531C5.42607 8.37271 5.66311 8.27642 5.92977 8.27642C6.19644 8.27642 6.43224 8.37271 6.63718 8.56531C6.84212 8.75543 6.94459 8.99864 6.94459 9.29494C6.94459 9.49987 6.8915 9.68259 6.78533 9.84308C6.67916 10.0011 6.54953 10.1184 6.39644 10.1949C6.24582 10.269 6.08903 10.306 5.92607 10.306C5.76311 10.306 5.60508 10.2665 5.452 10.1875C5.29891 10.106 5.16928 9.98753 5.06311 9.83197C4.9594 9.67395 4.90755 9.49617 4.90755 9.29864ZM5.68163 9.56531C5.76064 9.62457 5.84089 9.6542 5.92237 9.6542C6.00385 9.6542 6.08533 9.62333 6.16681 9.5616C6.24829 9.49987 6.28903 9.40852 6.28903 9.28753C6.28903 9.16654 6.25076 9.07642 6.17422 9.01716C6.09768 8.9579 6.01496 8.92827 5.92607 8.92827C5.83718 8.92827 5.75447 8.95913 5.67792 9.02086C5.60138 9.08259 5.56311 9.17395 5.56311 9.29494C5.56311 9.41345 5.60261 9.50358 5.68163 9.56531Z" fill="white"/>
          <path d="M8.49129 7.50975C8.51104 7.47271 8.54438 7.44679 8.59129 7.43197C8.6382 7.41716 8.69993 7.40975 8.77648 7.40975C8.85549 7.40975 8.91845 7.41716 8.96536 7.43197C9.01228 7.44679 9.04438 7.47271 9.06166 7.50975C9.08141 7.54679 9.09253 7.58012 9.09499 7.60975C9.09993 7.63691 9.1024 7.67889 9.1024 7.73568V9.94679C9.1024 10.0036 9.09993 10.0468 9.09499 10.0764C9.09253 10.1036 9.08141 10.1344 9.06166 10.169C9.02462 10.2357 8.91969 10.269 8.74685 10.269C8.57648 10.269 8.48141 10.2085 8.46166 10.0875C8.35796 10.206 8.21351 10.2653 8.02833 10.2653C7.80611 10.2653 7.60364 10.169 7.42092 9.97642C7.2382 9.78136 7.14685 9.55543 7.14685 9.29864C7.14685 9.03938 7.2382 8.81222 7.42092 8.61716C7.60611 8.4221 7.80857 8.32457 8.02833 8.32457C8.21104 8.32457 8.35302 8.38629 8.45425 8.50975V7.73197C8.45425 7.67518 8.45549 7.63321 8.45796 7.60605C8.4629 7.57642 8.47401 7.54432 8.49129 7.50975ZM7.89129 9.52086C7.95549 9.58753 8.03327 9.62086 8.12462 9.62086C8.21598 9.62086 8.29129 9.58753 8.35055 9.52086C8.41228 9.4542 8.44314 9.38136 8.44314 9.30234C8.44314 9.22086 8.41351 9.14555 8.35425 9.07642C8.29746 9.00728 8.21969 8.97271 8.12092 8.97271C8.02215 8.97271 7.94314 9.00728 7.88388 9.07642C7.82462 9.14308 7.79499 9.21716 7.79499 9.29864C7.79499 9.38012 7.82709 9.4542 7.89129 9.52086Z" fill="white"/>
          <path d="M9.39742 8.64679C9.39742 8.59 9.39866 8.54802 9.40113 8.52086C9.40607 8.49123 9.41718 8.4579 9.43446 8.42086C9.46903 8.35666 9.56533 8.32457 9.72335 8.32457C9.84187 8.32457 9.92582 8.34555 9.9752 8.38753C10.0172 8.4221 10.0394 8.47518 10.0419 8.54679C10.0443 8.57148 10.0456 8.60728 10.0456 8.6542V9.95049C10.0456 10.0073 10.0431 10.0505 10.0382 10.0801C10.0357 10.1073 10.0246 10.1394 10.0048 10.1764C9.97273 10.2406 9.87767 10.2727 9.71965 10.2727C9.56409 10.2727 9.46903 10.2394 9.43446 10.1727C9.41718 10.1357 9.40607 10.1036 9.40113 10.0764C9.39866 10.0493 9.39742 10.006 9.39742 9.94679V8.64679Z" fill="white"/>
          <path d="M10.3563 10.0986C10.3093 10.069 10.2859 10.0221 10.2859 9.9579C10.2859 9.8937 10.3328 9.79617 10.4266 9.66531C10.4538 9.62333 10.497 9.60234 10.5562 9.60234C10.618 9.60234 10.7007 9.63321 10.8044 9.69494C10.9081 9.7542 11.0019 9.78383 11.0859 9.78383C11.2365 9.78383 11.3118 9.7542 11.3118 9.69494C11.3118 9.64802 11.2291 9.61345 11.0637 9.59123C10.9081 9.56901 10.7538 9.51099 10.6007 9.41716C10.5291 9.37271 10.4698 9.30358 10.4229 9.20975C10.376 9.11345 10.3525 8.99987 10.3525 8.86901C10.3525 8.47889 10.6204 8.28383 11.1562 8.28383C11.3316 8.28383 11.5118 8.3258 11.697 8.40975C11.7834 8.44926 11.8266 8.49864 11.8266 8.5579C11.8266 8.61716 11.7995 8.69 11.7451 8.77642C11.6908 8.86284 11.6353 8.90605 11.5785 8.90605C11.5488 8.90605 11.4933 8.88753 11.4118 8.85049C11.3328 8.81345 11.2464 8.79494 11.1525 8.79494C11.0069 8.79494 10.934 8.8221 10.934 8.87642C10.934 8.95049 11.018 8.99864 11.1859 9.02086C11.3488 9.03815 11.5069 9.08506 11.66 9.1616C11.7316 9.19617 11.7908 9.25666 11.8377 9.34308C11.8871 9.42704 11.9118 9.53321 11.9118 9.6616C11.9118 9.79 11.8871 9.89987 11.8377 9.99123C11.7908 10.0801 11.7242 10.1468 11.6377 10.1912C11.4797 10.2678 11.2809 10.306 11.0414 10.306C10.8044 10.306 10.576 10.2369 10.3563 10.0986Z" fill="white"/>
          <path d="M0 3.5H13" stroke="white" stroke-width="1.5"/>
          </svg>
          
      </div>
    </div>

    <!-- Burger -->
    <div class="main-burger">
      <img [src]="'apps/landing-page/src/assets/main-burger.png'"/>       
    </div>

    <!-- Main address input container -->
    <div class="main-input-search-button">
        <!-- Input address area -->
        <div #inputScroll class="addres-search-parent">
          <address-search [scrollToInput]="(scrollToSearchInput$ | async)?.value || 'none'"></address-search>       
        </div>
    </div>
    
  </div>

  <div class="steps-container">

    <!-- Order Food -->

    <div class="steps-order">
      <!-- Header -->
      <div class="steps-header">
        <div class="steps-image">
          <img [src]="'apps/landing-page/src/assets/order-food-header.svg'"/> 
        </div>
        <div class="steps-title">
          {{ "order_food_header" | translate }}
        </div>
      </div>

      <!-- Content-->
      <div class="steps-content">

        <!-- Step 1: Location -->
        <div class="steps-content-item">
          <div class="steps-content-item-icon">
            <img [src]="'apps/landing-page/src/assets/order-food-step-location.svg'"/> 
          </div>
          <div class="steps-content-item-texts">
            <div class="steps-content-item-text-1">
              {{ "order_food_content_1_title" | translate }}
            </div>
            <div class="steps-content-item-text-2">
              {{ "order_food_content_1_subtitle" | translate }}
            </div>
          </div>
        </div>

        <!-- Step 2: Food browse -->
        <div class="steps-content-item">
          <div class="steps-content-item-icon">
            <img [src]="'apps/landing-page/src/assets/order-food-step-pizza.svg'"/> 
          </div>
          <div class="steps-content-item-texts">
            <div class="steps-content-item-text-1">
              {{ "order_food_content_2_title" | translate }}
            </div>
            <div class="steps-content-item-text-2">
              {{ "order_food_content_2_subtitle" | translate }}
            </div>
          </div>
        </div>

        <!-- Step 3: Enjoy food -->
        <div class="steps-content-item">
          <div class="steps-content-item-icon">
            <img [src]="'apps/landing-page/src/assets/order-food-step-send.svg'"/> 
          </div>
          <div class="steps-content-item-texts">
            <div class="steps-content-item-text-1">
              {{ "order_food_content_3_title" | translate }}
            </div>
            <div class="steps-content-item-text-2">
              {{ "order_food_content_3_subtitle" | translate }}
            </div>
          </div>
        </div>

        <!-- Step 4: Food browse -->
        <div class="steps-content-item no-mb">
          <div class="steps-content-item-icon">
            <img [src]="'apps/landing-page/src/assets/order-food-step-delivered.svg'"/> 
          </div>
          <div class="steps-content-item-texts">
            <div class="steps-content-item-text-1">
              {{ "order_food_content_4_title" | translate }}
            </div>
            <div class="steps-content-item-text-2">
              {{ "order_food_content_4_subtitle" | translate }}
            </div>
          </div>
        </div>
        
        <div class="vertical-line"></div>
        
      </div>

      <!-- Action buttons order-->
      <div class="steps-action-box">
          <div class="steps-action-box-text">{{ "order_food_action_info"  | translate}}</div>
          <!-- Looking for food -->
          <div class="steps-action-box-btn">
            <fs-button [text]="'order_food_action_cta' | translate"
              [type]="'raised-primary'" [leadingIcon]="'location_on'"
              [style]="'full-width-large-height'"
              (onPress)="onSearchFoodPress('medium')">
            </fs-button>
          </div>
      </div>
    </div>
    
    <!-- Restaurant steps -->

    <div class="steps-restaurant">
      <!-- Header -->
      <div class="steps-header">
        <div class="steps-image">
          <img [src]="'apps/landing-page/src/assets/restaurant-header.svg'"/> 
        </div>
        <div class="steps-title">
          {{ "restaurant_header" | translate }}
        </div>
      </div>

      <!-- Content-->
      <div class="steps-content">

        <!-- Step 1: Register -->
        <div class="steps-content-item">
          <div class="steps-content-item-icon">
            <img [src]="'apps/landing-page/src/assets/restaurant-step-register.svg'"/> 
          </div>
          <div class="steps-content-item-texts">
            <div class="steps-content-item-text-1">
              {{ "restaurant_content_1_title" | translate }}
            </div>
            <div class="steps-content-item-text-2">
              {{ "restaurant_content_1_subtitle" | translate }}
            </div>
          </div>
        </div>

        <!-- Step 2: Manage -->
        <div class="steps-content-item">
          <div class="steps-content-item-icon">
            <img [src]="'apps/landing-page/src/assets/restaurant-step-manage.svg'"/> 
          </div>
          <div class="steps-content-item-texts">
            <div class="steps-content-item-text-1">
              {{ "restaurant_content_2_title" | translate }}
            </div>
            <div class="steps-content-item-text-2">
              {{ "restaurant_content_2_subtitle" | translate }}
            </div>
          </div>
        </div>

        <!-- Step 3: Verified -->
        <div class="steps-content-item">
          <div class="steps-content-item-icon">
            <img [src]="'apps/landing-page/src/assets/restaurant-step-verified.svg'"/> 
          </div>
          <div class="steps-content-item-texts">
            <div class="steps-content-item-text-1">
              {{ "restaurant_content_3_title" | translate }}
            </div>
            <div class="steps-content-item-text-2">
              {{ "restaurant_content_3_subtitle" | translate }}
            </div>
          </div>
        </div>

        <!-- Step 4: Payment -->
        <div class="steps-content-item no-mb">
          <div class="steps-content-item-icon">
            <img [src]="'apps/landing-page/src/assets/restaurant-step-payment.svg'"/> 
          </div>
          <div class="steps-content-item-texts">
            <div class="steps-content-item-text-1">
              {{ "restaurant_content_4_title" | translate }}
            </div>
            <div class="steps-content-item-text-2">
              {{ "restaurant_content_4_subtitle" | translate }}
            </div>
          </div>
        </div>

        <div class="vertical-line"></div>
      </div>
      <!-- end restaurant steps-->

      <!-- Action buttons restaurant-->
      <div class="steps-action-box">
        <div class="steps-action-box-text">{{ "restaurant_action_info"  | translate}}</div>
        <!-- Collaborate as restaurant -->
        <div class="steps-action-box-btn">
          <fs-button [text]="'restaurant_action_cta' | translate"
            [url]="'restaurant'"
            [type]="'raised-primary'" [leadingIcon]="'search'"
            [style]="'full-width-large-height'">
          </fs-button>
        </div>
      </div>

    </div>

  </div>

  <!-- <mat-divider class="divider-spacing"></mat-divider> -->

  <div class="download-container">
    <!-- Download container-->
    <div class="download-native-apps">
      <div class="download-header">
        <img class="mr-3" [src]="'apps/landing-page/src/assets/devices-download-white.svg'">
        {{ "download_header_text" | translate }}
      </div>
      <div class="download-buttons">
        <div class="download-btn" (click)="onGooglePlayClick()">
          <img [src]="'apps/landing-page/src/assets/logo-google-play.svg'">
          <div class="download-btn-text google">
            {{ "download_text_google" | translate }}
          </div>
        </div>
        <div class="download-btn" (click)="onAppStoreClick()">
          <img [src]="'apps/landing-page/src/assets/logo-app-store.svg'">
          <div class="download-btn-text apple">
             {{ "download_text_apple" | translate }}
          </div>
        </div>
      </div>
    </div>

    <!-- Empty container - to add things-->
    <div class="download-next-container"></div>

    <!-- Bicycle & Ferris wheel -->
    <div class="bike-wheel-container">
      <div class="white-bike">
        <img [src]="'apps/landing-page/src/assets/bicycle-white.svg'">
      </div>
      <div class="ferris-wheel">
        <img [src]="'apps/landing-page/src/assets/ferris-wheel.svg'">
      </div>
    </div>
  </div>
  

  <!-- <mat-divider class="divider-spacing"></mat-divider> -->

  <div class="startup-info-container">
      <div class="startup-container">

        <div class="startup-container-image">
          <img [src]="'apps/landing-page/src/assets/support-delivery-startup.svg'">
        </div>

        <div class="startup-container-texts">
          <div class="start-up-title">
            {{ "startup_title" | translate }}
          </div>
  
          <!-- Made with love -->
          <div class="made-with">
            {{ 'startup_subtitle_1' | translate }}<mat-icon class="heart-icon">favorite</mat-icon>{{ 'startup_subtitle_2' | translate }}                                
          </div>
        </div>
       
      </div>
      <div class="district-availability-container">
        <div class="startup-container-image">
          <img [src]="'apps/landing-page/src/assets/district-map-availability.svg'">
        </div>

        <div class="startup-container-texts">
          <div class="start-up-title ml-2">
            {{ "district_availability_title" | translate }}
          </div>
  
          <!-- Button check expansion plan-->
          <div class="steps-action-box-btn">
            <fs-button [text]="'district_availability_cta' | translate"
              [type]="'raised'"
              [url]="'expansion-plan'">
            </fs-button>
          </div>

        </div>
      </div>
  </div>
  

  <!-- Socials -->
  <div class="socials-info-container">
    <div class="socials-container">
      <div class="socials-title">
        {{ "socials_follow" | translate }}:
      </div>
      <div class="socials-logos">
        <a href="https://facebook.com"><img [src]="'apps/landing-page/src/assets/logo-facebook.svg'" [title]="'Facebook'"></a>
        <a href="https://instagram.com"><img [src]="'apps/landing-page/src/assets/logo-instagram.svg'" [title]="'Instagram'"></a>
        <a href="https://x.com"><img [src]="'apps/landing-page/src/assets/logo-x.svg'" [title]="'X'"></a>
        <a href="https://linkedin.com"><img [src]="'apps/landing-page/src/assets/logo-linkedin.svg'" [title]="'Linedin'"></a>
        <a href="https://tiktok.com"><img [src]="'apps/landing-page/src/assets/logo-tiktok.svg'" [title]="'TikTok'"></a>
        <a href="https://youtube.com"><img [src]="'apps/landing-page/src/assets/logo-youtube.svg'" [title]="'Youtube'"></a>
      </div>
    </div>
  </div>

  <!-- Bottom part -->  
  <landing-footer>
  </landing-footer>

</ng-container>

<!-- 
  Countdown
-->
<ng-template #showCountdown>
    <div class="flex flex-col pt-16 text-center justify-center justify-items-center">
        <fs-logo></fs-logo>
        <div class="pt-5 pb-5">
            <span class="font-bold"> Landing Page - Work in progress <mat-icon>build</mat-icon> </span>
        </div>
    
        <div class="pt-5 pb-5">
            <div class="pb-5"> Go Live Date: <strong>{{ deadline }}</strong></div>
            <div [id]="'clockdiv'">
                <div>
                    <span class="days"></span>
                    <div class="smalltext">Days</div>
                  </div>
                  <div>
                    <span class="hours"></span>
                    <div class="smalltext">Hours</div>
                  </div>
                  <div>
                    <span class="minutes"></span>
                    <div class="smalltext">Minutes</div>
                  </div>
                  <div>
                    <span class="seconds"></span>
                    <div class="smalltext">Seconds</div>
                  </div>
            </div>
        </div>
      </div>
</ng-template>
