<!-- 
    Desktop and mobile main container
-->
<ng-container *ngIf="landingHiddenVisible$ | async">
    <div class="main-container">

        <!-- Logo -->
        <div class="left-container">
            <div class="nav-logo">
                <fs-logo [type]="Product.LADING_PAGE" (onPress)="onLogoPress()"></fs-logo>
            </div>
        </div>

        <div class="right-container">

            <ng-container *ngIf="(isLoggedIn$() | async); else visitorProfile">
                <div class="logged-in-basis">
                    <button mat-button [matMenuTriggerFor]="menu">
                        <div class="logged-in-container">
                            <img [src]="'apps/landing-page/src/assets/nav-bar-profile.svg'" alt="Profile name"/>
                            <div class="logged-in-text"> {{ (selfData$ | async)?.name }}</div>
                            <img [src]="'apps/landing-page/src/assets/arrow-drop-down-address.svg'" alt="Food profile details"/>
                        </div>
                    </button>
                    <mat-menu #menu="matMenu">

                        <!-- User-App type of user / product -->
                        <ng-container *ngIf="(selfData$ | async)?.product === Product.USER_APP.toLocaleUpperCase()">
                            <!-- Menu buttons-->
                            <a [href]="getUserAppBaseLink() + '/user/profile'"><button mat-menu-item><mat-icon class="mr-3" class="default-icon-color">person_outline</mat-icon>{{ "nav_bar_menu_profile" | translate }}</button></a>
                            <a [href]="getUserAppBaseLink() + '/user/orders'"><button mat-menu-item><mat-icon class="mr-3" class="default-icon-color">list</mat-icon>{{ "nav_bar_menu_orders"  | translate }}</button></a>
                            <a [href]="getUserAppBaseLink() + '/user/address'"><button mat-menu-item><mat-icon class="mr-3" class="default-icon-color">location_on</mat-icon>{{ "nav_bar_menu_address"  | translate }}</button></a>
                            <a [href]="getUserAppBaseLink() + '/user/payment'"><button mat-menu-item><mat-icon class="mr-3" class="default-icon-color">payment</mat-icon>{{ "nav_bar_menu_payment"  | translate }}</button></a>
                            <button mat-menu-item (click)="doLogout()"><mat-icon class="mr-3" class="default-icon-color">exit_to_app</mat-icon>{{ "nav_bar_menu_logout"  | translate }}</button>
                        </ng-container>

                        <!-- Restaurant -->
                        <ng-container *ngIf="
                            (selfData$ | async)?.product === Product.RESTAURANT.toLocaleUpperCase() ||
                            (selfData$ | async)?.product === Product.DRIVER.toLocaleUpperCase() ||
                            (selfData$ | async)?.product === Product.KITCHEN.toLocaleUpperCase() ||
                            (selfData$ | async)?.product === Product.ADMIN.toLocaleUpperCase()
                        ">
                            <!-- Menu buttons-->
                            <button mat-menu-item (click)="doLogout()"><mat-icon class="mr-3" class="default-icon-color">exit_to_app</mat-icon>{{ "nav_bar_menu_logout"  | translate }}</button>
                        </ng-container>

                        <!-- TODO - kitchen / driver -->
                        
                    </mat-menu>
                </div>
            </ng-container>
            <ng-template #visitorProfile>
                <div class="login-register-buttons">
                    <fs-button [text]="'top_nav_login_button' | translate"
                        [type]="'raised'" [leadingIcon]="'account_circle'"
                        [style]="'full-width-large-height'"
                        (onPress)="onLoginPress()"
                        class="logout-btn">
                    </fs-button>
    
                    <fs-button [text]="'top_nav_register_button' | translate"
                        [type]="'raised-primary'" [leadingIcon]="'person_add'"
                        [style]="'full-width-large-height'"
                        (onPress)="onRegisterPress()"
                        class="logout-btn">
                    </fs-button>
                </div>
            </ng-template>

            <!-- Language menu -->
            <div class="language-container">
                <nav-language-selector></nav-language-selector>
            </div>

            <div class="restaurant-business">
                {{ "top_nav_restaurant_business" | translate }}:
            </div>
            

            <fs-button [text]="'top_nav_restaurant_button' | translate"
                [type]="'raised-primary'" [leadingIcon]="'store'"
                [style]="'full-width-large-height'"
                (onPress)="onRestaurantPress()"
                [url]="'/restaurant'"
                class="logout-btn">
            </fs-button>
        </div>
    </div>
</ng-container>
