import { AfterViewInit, Component, OnInit } from '@angular/core';
import {environment} from "../environments/environment";
import { BehaviorSubject, combineLatest } from 'rxjs';
import { SharedServicesInit } from 'libs/shared-services/src/lib/shared-services-init';
import { LandingMainServicesInitService } from './services/landing-main-services-init.service';

@Component({
  selector: 'foodis-landing-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  title = 'landing-page';

  public isStaging$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  public allLoaded$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public baseServicesLoaded$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public appServicesLoaded$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(
    private sharedServicesInit: SharedServicesInit,
    private landingMainServices: LandingMainServicesInitService
  ) {

  }

  public ngOnInit(): void {
      // wait for base services to load first
      this.sharedServicesInit.allServicesLoaded$().subscribe((value) => {
        if (value) {
          this.baseServicesLoaded$.next(true);
          this.loadAppServices();
        }
      });

      combineLatest([
          this.baseServicesLoaded$,
          this.landingMainServices.allServicesLoaded$()
          ]).subscribe(
          ([baseFinished, mainAppServices]) => {
            if (mainAppServices) {
              this.appServicesLoaded$.next(true);
            }
            if (baseFinished && mainAppServices) {
              this.allLoaded$.next(true);
            }
          }
      );

      this.isStaging$.next(environment.DEBUG_STAGING_BADGE);
  }

  private loadAppServices() {
    this.landingMainServices.initServices();
  }
}
