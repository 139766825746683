import { AfterViewInit, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DistrictState, DistrictStateFillColor, VIENNA_DISTRICTS, mapViennaOptionsCenter } from 'libs/shared-models/src/lib/utils/address-constants';

@Component({
  selector: 'district-map',
  templateUrl: './district-map.component.html',
  styleUrls: ['./district-map.component.scss'],
})
export class DistrictMapComponent implements OnInit, AfterViewInit {

  constructor(
    private router: Router,
    private route: ActivatedRoute,
  ) {
  }

  public ngOnInit() {
  }

  // Needed in the viewAfterInit because of the searchInput which needs to be loaded
  public ngAfterViewInit(): void {
    // uses the property added in index.html which waits for the async google map script to load
    if ((window as any).googleMapsInitialized) {
      this.initMap();
    } else {
      // Wait for the googleMapsLoaded event before initializing the map
      document.addEventListener('googleMapsLoaded', () => {
        this.initMap();
      });
    }
  }

  private map: any;
  private featureLayer: any;

  async initMap() {
    // Request needed libraries.


    const map = new google.maps.Map(document.getElementById("map")  as HTMLElement, {
      center: mapViennaOptionsCenter,
      zoom: 12.7,
      mapTypeId: "roadmap",
    });
    // Define the LatLng coordinates for the polygon's path.

    let polygonDrawingList: any[] = [];

    VIENNA_DISTRICTS.forEach((district) => {
      // google expected format "lat" / "lng"
      const polygonCoordinates = district.coordinatePoints.map((item) => {
        return {
          lat: item[0],
          lng: item[1]
        }
      });

      let fillColor = "";
      switch (district.state) {
        case DistrictState.ACTIVE:
          fillColor = DistrictStateFillColor.ACTIVE;
          break;
        case DistrictState.SOON:
          fillColor = DistrictStateFillColor.SOON;
          break;
        case DistrictState.NOT_STARTED:
          fillColor = DistrictStateFillColor.NOT_STARTED;
          break;
        default:
          fillColor = "#ffffff";
      }

      const polygonDrawing = new google.maps.Polygon({
        paths: polygonCoordinates,
        strokeColor: "#5E9925",//"#FFFFFF",
        strokeOpacity: 0.8,
        strokeWeight: 2,
        fillColor: fillColor,
        fillOpacity: 0.5,
      });
      polygonDrawingList.push({
        polygon: polygonDrawing,
        districtInfo: district,
        fillColor: fillColor
      });
    })

    // show them on the map
    polygonDrawingList.forEach((item) => item.polygon.setMap(map));



    // Set click info:
    const infoWindow = new google.maps.InfoWindow();

    polygonDrawingList.forEach((item) => item.polygon.addListener("mouseover", () => {

      const contentString =
        '<div class="pl-2 pr-2">' +
        '<div class="text-lg pb-5"><b>' + item.districtInfo.name + ', ' + item.districtInfo.postalCode + '</b></div>' +
        '<div class="text-base"> Status: <span style="font-weight: 500;color: ' + item.fillColor + '">' + item.districtInfo.state + '<span></div>' +
        '<div class-"text-base"> Active restaurants: 0 </div>' +
        '</div>'

      infoWindow.setContent(contentString);
      infoWindow.setPosition({lat: item.districtInfo.center[0], lng: item.districtInfo.center[1]});
      infoWindow.open(map);
    }));
  
  }

}
