import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppComponent } from './app.component';
import { RouterModule } from '@angular/router';
import { appRoutes } from './app.routes';
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import {SharedServicesModule} from "@web-foodis/shared-services";
import {SharedUiModule} from "@web-foodis/shared-ui";
import {environment} from "../environments/environment";
import { LetMeInComponent } from './components/letmein/letmein.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { LandingTopNavBarComponent } from './components/landing-top-nav-bar/landing-top-nav-bar.component';
import { RestaurantOfferingComponent } from './components/restaurant-offering/restaurant-offering.component';
import { ExpansionPlanComponent } from './components/expansion-roadmap/expansion-plan.component';
import { LandingFooterComponent } from './components/landing-footer/landing-footer.component';
import { AddressSearchComponent } from './components/address-search/address-search.component';
import { GoogleMapsModule } from '@angular/google-maps';
import { DistrictMapComponent } from './components/district-map/district-map.component';

@NgModule({
  declarations: [
    AppComponent, 
    LetMeInComponent,
    DashboardComponent,
    LandingTopNavBarComponent,
    RestaurantOfferingComponent,
    ExpansionPlanComponent,
    LandingFooterComponent,
    AddressSearchComponent,
    DistrictMapComponent
  ],
  imports: [
    BrowserModule,
    RouterModule.forRoot(appRoutes, { initialNavigation: 'enabledBlocking' }),
    BrowserAnimationsModule,
    GoogleMapsModule,
    SharedServicesModule.forRoot(environment),
    SharedUiModule,
  ],
  providers: [SharedServicesModule],
  bootstrap: [AppComponent],
})
export class AppModule {}
