<!-- Staging badge -->
<ng-container *ngIf="isStaging$ | async">
  <fs-debug-badge></fs-debug-badge>
</ng-container>

<!-- Main content -->
<ng-container *ngIf="allLoaded$ | async; else loadingScreen">

  <!-- Fixed top nav bar-->
  <landing-top-nav-bar></landing-top-nav-bar>

  <!-- All the other content-->
  <div class="main-app-container">
      <router-outlet></router-outlet>
  </div>
</ng-container>

<ng-template #loadingScreen>
  <!-- Loading -->
</ng-template>

