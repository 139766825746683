import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'restaurant-offering',
  templateUrl: './restaurant-offering.component.html',
  styleUrls: ['./restaurant-offering.component.scss'],
})
export class RestaurantOfferingComponent implements OnInit {

  constructor(
    private router: Router,
    private route: ActivatedRoute,
  ) {
    
  }

  public ngOnInit() {
  }
}
