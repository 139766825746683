import { AfterViewInit, Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { DistrictState, VIENNA_DISTRICTS } from 'libs/shared-models/src/lib/utils/address-constants';

@Component({
  selector: 'expansion-plan',
  templateUrl: './expansion-plan.component.html',
  styleUrls: ['./expansion-plan.component.scss'],
})
export class ExpansionPlanComponent implements AfterViewInit {

  public selectedSubscribeDistrict: number = 0;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
  ) {
    
  }

  public ngAfterViewInit(): void {
    window.scrollTo(0, 0); // Scroll to the top of the page
  }


  public emailUpdate(value: any) {

  }

  public onEmailSubscribePress() {

  }

  public getDistricts(): any[] {
    return VIENNA_DISTRICTS.filter((item) => item.state !== DistrictState.ACTIVE).map((item) => item.actualNumber + ". " + item.name);
  }

  public onSelectDistrict(value: any) {

  }
}
