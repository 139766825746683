import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CookieService } from 'libs/shared-services/src/lib/cookie.service';
import { LandingHiddenService } from '../../services/landing-hidden.service';

@Component({
  selector: 'let-me-in',
  templateUrl: './letmein.component.html',
  styleUrls: ['./letmein.component.scss'],
})
export class LetMeInComponent implements OnInit {

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private landingHiddenService: LandingHiddenService
  ) {
    
  }

  public ngOnInit() {
    const cookieValue = this.route.snapshot.url[0].path === "letmein";    
    this.landingHiddenService.setHiddenVersion(cookieValue);
    this.router.navigateByUrl("/");
  }
}
