<div class="main-expansion">
    <div class="header">
        <!-- Title -->
        <div class="pr-4">{{ "expansion_title" | translate }}</div>
        <!-- Colors legend -->
        <div class="color-legend-container">
            <div class="color-item">
                <div class="circle green"></div>
                <div class="circle-text green">{{ "expansion_active" | translate }}</div>
            </div>
            <div class="color-item">
                <div class="circle yellow"></div>
                <div class="circle-text yellow">{{ "expansion_soon" | translate }}</div>
            </div>
            <div class="color-item">
                <div class="circle gray"></div>
                <div class="circle-text gray">{{ "expansion_not_started" | translate }}</div>
            </div>
        </div>
    </div>
    <district-map></district-map>

    <div class="fine-tune">
        <img [src]="'apps/landing-page/src/assets/support-delivery-startup.svg'">
        <div class="pl-3">{{ "expansion_startup_grow" | translate }}</div> 
    </div>

    <div class="email-container">
        <!-- icon -->
        <img [src]="'apps/landing-page/src/assets/email.svg'">
        
        <!-- title & buttons -->
        <div class="pl-5">
            <div class="district-subscribe-text">
                {{ "expansion_notification" | translate }}
            </div>
            <div class="district-subscribe-row2">
                
                <div class="district-email">
                    <fs-input [label]="'expansion_email' | translate"
                      [subscriptSizing]="'dynamic'" 
                      [style]="'full-width'" (valueUpdate)="emailUpdate($event)">
                    </fs-input>
                </div>
                <div class="district-dropdown">
                    {{ "expansion_district" | translate }}:
                    <fs-menu-simple
                        [itemsList]='getDistricts()'
                        [selectedItem]='selectedSubscribeDistrict'
                        [leadingIcon]="'./libs/shared-ui/assets/images/location-pin-main.svg'"
                        (onSelect)="onSelectDistrict($event)">
                    </fs-menu-simple>
                </div>
                <div class="district-subscribe-btn">
                    <fs-button [text]="'expansion_subscribe_cta' | translate"
                        [type]="'raised-primary'" 
                        [style]="'full-width-large-height'"
                        (onPress)="onEmailSubscribePress()">
                    </fs-button>
                </div>

                
            </div>
        </div> 
    </div>
</div>

<!-- Footer -->
<landing-footer></landing-footer>