import { Injectable} from '@angular/core';
import { LocaleService } from 'libs/shared-services/src/lib/locale.service';
import { BaseLoadingService } from 'libs/shared-services/src/lib/base-loading';
import { ApiBaseService } from 'libs/shared-services/src/lib/api-base.service';
import { ToasterService } from 'libs/shared-services/src/lib/toaster.service';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { AuthService } from 'libs/shared-services/src/lib/auth.service';
import { environment } from '../../environments/environment';
import { UserSelfResponse } from '../models/user-self-response';

@Injectable({
    providedIn: 'root',
})
export class LandingTopNavBarService extends BaseLoadingService {
    
    private userSelfData$: BehaviorSubject<UserSelfResponse> = new BehaviorSubject<UserSelfResponse>(new UserSelfResponse());
    

    constructor(        
        private apiService: ApiBaseService,
        private toasterService: ToasterService,
        private authService: AuthService,
        private localeService: LocaleService,
    ) {
        super();           
    }    

    public initNavBar() {
        // self data if the user doesn't have it already fetched (id)
        if (this.authService.isLoggedIn() && !this.getSelfData().id) {
            this.fetchSelfData();
        } else {
            this.setFinished();
        }
    }

    /*
        Self user data
    */
    public fetchSelfData() {
        this.apiService.get(environment.API_USER_SELF).subscribe({
            next: (res: any) => {
                const obj =  Object.assign(new UserSelfResponse(), res);
                this.setSelfData(obj);
                this.setFinished();
            },
            error: (err) => {
                this.toasterService.showWarning(this.localeService.translate("nav_bar_token_expired"), err?.error?.message);
                this.authService.doLogout();
                this.setFinished();
            }
        });
    }

    public getSelfData$(): Observable<UserSelfResponse> {
        return this.userSelfData$.asObservable();
    }

    public getSelfData(): UserSelfResponse {
        return this.userSelfData$.getValue();
    }

    public setSelfData(data: UserSelfResponse) {
        this.userSelfData$.next(data);     
    }    
    
    public clearSelfData() {
        this.userSelfData$.next(new UserSelfResponse());
    }
}