import { Route } from '@angular/router';
import { LetMeInComponent } from './components/letmein/letmein.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { RestaurantOfferingComponent } from './components/restaurant-offering/restaurant-offering.component';
import { ExpansionPlanComponent } from './components/expansion-roadmap/expansion-plan.component';

export const appRoutes: Route[] = [
    { path: '', component: DashboardComponent,  pathMatch: 'full'},
    { path: 'restaurant', component: RestaurantOfferingComponent,  pathMatch: 'full'},
    { path: 'expansion-plan', component: ExpansionPlanComponent,  pathMatch: 'full'},
    
    
    // Debug routes
    { path: 'letmein', component: LetMeInComponent,  pathMatch: 'full'},
    { path: 'letmeout', component: LetMeInComponent,  pathMatch: 'full'},
];
